import * as React from 'react';
import { graphql, Link } from 'gatsby';
import Helmet from 'react-helmet';
import { css } from '@emotion/css';
import MainLayout from '../layouts/MainLayout';
import * as main from '../layouts/MainLayout.css';
import { PageContext } from '../templates/post';
import Article from '../components/Article';
import ArticlePoster from '../components/ArticlePoster';

export interface HomeData {
  logo: {
    childImageSharp: {
      fixed: any;
    };
  };
  header: {
    childImageSharp: {
      fluid: any;
    };
  };
  allMarkdownRemark: {
    edges: Array<{
      node: PageContext;
    }>;
  };
}

const HomePage: React.FunctionComponent<{data: HomeData}> = props => {
  const width = props.data.header.childImageSharp.fluid.sizes.split(', ')[1].split('px')[0];
  const height = String(Number(width) / props.data.header.childImageSharp.fluid.aspectRatio);
  const current = props.data.allMarkdownRemark.edges[0];
  return (
    <MainLayout>
      <Helmet>
        <link rel="shortcut icon" href={props.data.logo.childImageSharp.fixed.src} />
        <meta property="og:image" content={props.data.header.childImageSharp.fluid.src} />
        <meta property="og:image:width" content={width} />
        <meta property="og:image:height" content={height} />
        <meta name="twitter:image" content={props.data.header.childImageSharp.fluid.src} />
      </Helmet>
      <Article className={main.main} post={current.node}/>
      <div className={main.also}>
        <div className={main.heading}>ALL POSTS</div>
        {props.data.allMarkdownRemark.edges.map(p => {
          return (
            <Link className={`post-card-image-link ${nzPostCard}`} to={p.node.fields.slug}>
              <ArticlePoster post={p.node}/>
            </Link>
          );
        })}
      </div>
    </MainLayout>
  );
};

export default HomePage;

export const pageQuery = graphql`
  query {
    logo: file(relativePath: { eq: "img/star-768x768.png" }) {
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed
        }
      }
    }
    header: file(relativePath: { eq: "img/nezaboodka.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { draft: { ne: true } } },
      sort: { fields: [frontmatter___date], order: DESC },
      limit: 2000
    ) {
      edges {
        node {
          timeToRead
          frontmatter {
            lang
            title
            date
            date_year: date(formatString: "YYYY")
            date_month: date(formatString: "MMM")
            date_day: date(formatString: "D")
            tags
            image {
              childImageSharp {
                fluid(maxWidth: 3720) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            author {
              id
              bio
              avatar {
                children {
                  ... on ImageSharp {
                    fixed(quality: 100) {
                      src
                    }
                  }
                }
              }
            }
          }
          excerpt
          html
          htmlAst
          fields {
            layout
            slug
          }
        }
      }
    }
  }
`;

const nzPostCard = css`
  :hover {
    img {
      opacity: 1 !important;
    }
  }
`;

// const sHomePage = css`
//   @media (min-width: 795px) {
//     .post-card:nth-child(6n + 1):not(.no-image) {
//       flex: 1 1 100%;
//       flex-direction: row;
//     }

//     .post-card:nth-child(6n + 1):not(.no-image) .post-card-image-link {
//       position: relative;
//       flex: 1 1 auto;
//       border-radius: 5px 0 0 5px;
//     }

//     .post-card:nth-child(6n + 1):not(.no-image) .post-card-image {
//       position: absolute;
//       width: 100%;
//       height: 100%;
//     }

//     .post-card:nth-child(6n + 1):not(.no-image) .post-card-content {
//       flex: 0 1 357px;
//     }

//     .post-card:nth-child(6n + 1):not(.no-image) h2 {
//       font-size: 2.6rem;
//     }

//     .post-card:nth-child(6n + 1):not(.no-image) p {
//       font-size: 1.8rem;
//       line-height: 1.55em;
//     }

//     .post-card:nth-child(6n + 1):not(.no-image) .post-card-content-link {
//       padding: 30px 40px 0;
//     }

//     .post-card:nth-child(6n + 1):not(.no-image) .post-card-meta {
//       padding: 0 40px 30px;
//     }
//   }
// `;
